<template>
  <div class="signup-invitation" v-if="!isProcessing">
    <invitation-form class="signup-invitation__form" @set-invitation-code="setInvitationCode"
                     :check-result="checkResult" :price="price" :price-cycle="priceCycle" :service-name="serviceName" />
    <common-button class="signup-invitation__submit" text="Googleでアカウント作成" :is-active="isActive" @emit-event="doCreate()" />
  </div>
</template>

<script>
import InvitationForm from '@/components/signup/form'
import CommonButton from '@/components/common/button'

// 発行されていない招待コードのときの状態
const NOT_EXIST_RESULT = { exist: false }
// アカウント提供元の名前
const ACCOUNT_PROVIDER = 'google'

export default {
  name: 'signup_invitation',
  components: { InvitationForm, CommonButton },
  data () {
    return {
      invitationCode: '',
      checkResult: NOT_EXIST_RESULT
    }
  },
  async mounted () {
    if (!this.existInvitationSetting) await this.$store.dispatch('settingInvitation/getInvitationSetting')
    if (!this.invitationUsed) {
      this.$router.push({ name: 'notfound' })
    }
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Boolean} アカウント作成ボタンを押下可能か
     */
    isActive () {
      return this.checkResult.exist
    },
    /**
     * @return {Number} 月額料金
     */
    price () {
      return Number(process.env.VUE_APP_PRICE).toLocaleString()
    },
    /**
     * @return {String} 課金頻度
     */
    priceCycle () {
      return String(process.env.VUE_APP_PRICE_CYCLE)
    },
    /**
     * @return {String} サービス名
     */
    serviceName () {
      return this.$store.getters['settingGeneral/settingGeneral']('serviceName').value
    },
    /**
     * @return {Boolean} 招待機能情報の存在有無
     */
    existInvitationSetting () {
      return this.$store.getters['settingInvitation/existSetting']
    },
    /**
     * @return {Boolean} 招待機能を利用しているか
     */
    invitationUsed () {
      return this.$store.getters['settingInvitation/used']
    }
  },
  methods: {
    /**
     * 入力された招待コードをセットする.
     * 有効であれば課金情報を表示させ作成ボタンをアクティブにする.
     * @param {String} inputCode 入力された招待コードの値
     */
    async setInvitationCode (inputCode) {
      this.invitationCode = inputCode
      if (/^(\d){8}$/.test(inputCode)) {
        const checkCode = await this.$store.dispatch('functions/checkCode', inputCode)
        if (checkCode.exist) {
          this.checkResult = Object.assign({}, checkCode)
        } else {
          this.checkResult = Object.assign({}, NOT_EXIST_RESULT)
        }
      } else {
        this.checkResult = Object.assign({}, NOT_EXIST_RESULT)
      }
    },
    /**
     * アカウントの作成
     * 招待コードを利用履歴に保存するためセッションに保存する
     */
    doCreate () {
      this.$store.commit('setSubmitting', true)
      sessionStorage.setItem('isSignupUser', true)
      sessionStorage.setItem('invitationCode', this.invitationCode)
      this.$store.dispatch('auth/signin', ACCOUNT_PROVIDER)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.signup-invitation {
  width: 100vw;
  max-width: $max_width;
  padding: 0 $padding_width $padding_height;
  margin: $header_height auto 0 auto;
  &__form {
    margin-top: $unit_size * 3;
  }
  &__submit {
    display: block;
    margin: $unit_size * 5 auto 0 auto;
  }
}
</style>
