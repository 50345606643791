<template>
  <div class="signup-form">
    <h2 class="signup-form__title">{{serviceName}}への招待</h2>
    <input v-model="invitationCode" @input="inputInvitationCode(invitationCode)" class="signup-form__input" placeholder="招待コードを入力してください" />
    <div v-if="isEntered" class="signup-form__info">
      <!-- タイトル -->
      <h3 :class="isExist ?'signup-form__info__title' : 'signup-form__info__title--error'">
        <v-icon class="signup-form__info__title__icon">info</v-icon>
        {{ isExist ? '招待コードから登録した場合のお支払いについて' : '招待コードが存在しません' }}
      </h3>
      <!-- 招待コード情報 -->
      <table v-if="isExist" class="signup-form__info__table">
        <tr class="signup-form__info__table__tr" v-for="(billingGuid, n) in billingGuids" :key="n">
          <th class="signup-form__info__table__tr__th">{{ billingGuid.key }}</th>
          <td class="signup-form__info__table__tr__td">{{ billingGuid.value }}</td>
        </tr>
      </table>
      <!-- 説明文 -->
      <p v-if="isExist && !isImmediateCode" class="signup-form__info__warning">
        ※ 課金が開始される日付までは支払いは発生しません。それ以降は会員登録時にご登録いただくカード情報に{{priceCycle}}毎に{{ price }}円（税込）の支払いが発生します。</p>
      <p v-if="!isExist" class="signup-form__info__warning">
        招待コードを招待者に確認の上、再度入力をお願いします。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * 入力された招待コードの確認結果(以下構造)
     * - exist: true or false (招待コードが存在するかどうか)
     * - billingStartDay: YYYY/MM/DD (課金開始日.existがtrueのときのみ存在する.)
     */
    checkResult: {
      type: Object,
      required: true
    },
    /**
     * 課金頻度で発生する料金
     * ex: 300, 4,000
     */
    price: {
      type: String,
      required: true
    },
    /**
     * 課金頻度
     * ex: 月, 2ヶ月
     */
    priceCycle: {
      type: String,
      required: true
    },
    /**
     * 登録先サービス名
     */
    serviceName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      /**
       * 入力された招待コードの値
       */
      invitationCode: ''
    }
  },
  computed: {
    /**
     * @return {Boolean} 招待コードが入力されているかどうか
     */
    isEntered () {
      return this.invitationCode.length > 0
    },
    /**
     * @return {Boolean} 存在有無
     */
    isExist () {
      return this.checkResult.exist
    },
    /**
     * @return {Object[]} 登録時の課金案内
     */
    billingGuids () {
      const billingGuids = [{ key: '利用料金', value: `${this.price} 円/${this.priceCycle}（税込）` }]
      if (this.isExist) {
        billingGuids.push({ key: '課金開始日', value: this.checkResult.billingStartDay })
      }
      return billingGuids
    },
    /**
     * @return {Boolean} 即日課金の招待コードか
     */
    isImmediateCode () {
      return this.checkResult.billingStartDay && this.checkResult.billingStartDay === '本日'
    }
  },
  methods: {
    /**
     * 招待コードの入力値を親コンポーネントに渡す
     * @param {String} inputCode 入力された招待コード
     */
    inputInvitationCode (inputCode) {
      this.$emit('set-invitation-code', inputCode)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";
@import "@/assets/sass/fontfamily.scss";

.signup-form {
  &__title {
    font-size: 1.4rem;
    text-align: center;
  }
  &__input {
    width: 100%;
    padding: $unit_size $unit_size * 2;
    margin-top: $unit_size * 2;
    font-size: 1.6rem;
    text-align: center;
    border: 1px solid $gray_darken_color;
    border-radius: 4px;
    &::placeholder {
      color: $gray_darken_color;
    }
    &:focus {
      outline: none;
    }
  }
  &__info {
    width: 100%;
    padding: $unit_size;
    margin-top: $unit_size * 5;
    background-color: $gray_lighten_color;
    &__title {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 2rem;
      color: $black_color;
      &--error {
        @extend .signup-form__info__title;
        color: $red_color !important;
      }
      &__icon {
        margin-right: $unit_size;
        font-family: $material-outlined;
        font-size: 2rem;
        &.theme--light.v-icon {
          color: inherit;
        }
      }
    }
    &__warning {
      margin: $unit_size 0 0;
      color: $gray_darken_color;
    }
    &__table {
      width: 100%;
      margin-top: $unit_size;
      font-size: 1.2rem;
      border-spacing: 0 $unit_size;
      &__tr {
        &__th {
          font-weight: normal;
        }
        &__td {
          font-weight: bold;
          text-align: right;
        }
      }
    }
  }
}
</style>
