<template>
  <button class="button" :disabled="!isActive" @click="emitEvent()" outlined>{{ text }}</button>
</template>

<script>
export default {
  props: {
    // 表示するテキスト
    text: {
      type: String,
      required: true
    },
    // ボタンが活性状態かどうか（デフォルトはtrue）
    isActive: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    /**
     * 指定された処理を実行する
     */
    emitEvent () {
      this.$emit('emit-event')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.button {
  padding: $unit_size $unit_size * 2;
  font-size: 1.4rem;
  color: $white_color;
  background-color: $concept_color;
  border-radius: 10px;
  &:disabled {
    background-color: $concept_transparent_color;
  }
}
</style>
